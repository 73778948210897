import { ChangeDetectionStrategy, Component, DestroyRef, ElementRef, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { GlobalEventsService } from '@coin/shared/util-helpers';
import { CoinUser, UserMenuItem } from '@coin/shared/util-models';
import { SetUserImageService } from '@coin/shared/data-access';
import { TokenService, UserState } from '@coin/modules/auth/data-management';
import { AuthService } from '@coin/modules/auth/data-access';
import { ConfirmationDialogComponent } from '@coin/shared/feature-legacy-components';
import { toggleIconAnimation } from '@coin/shared/util-animations';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Employee } from '../../../../api/generated';
import { fadeAnimation } from '../../../../shared/animations';
import { EmulationService } from './services/emulation.service';
import { LanguageMenuService } from './services/language-menu.service';
import { LoadUserDetails, LoadUserImage } from './state/user-menu.actions';
import { UserMenuState } from './state/user-menu.state';

@Component({
  selector: 'cosmos-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeAnimation, toggleIconAnimation],
  standalone: false
})
export class UserMenuComponent implements OnInit {
  public isMenuVisible$ = new BehaviorSubject<boolean>(false);

  public user$: Observable<CoinUser> = this.store.select(UserState?.user);
  public userImageSrc$: Observable<string> = this.store.select(UserMenuState?.userImageSource);
  public userDetails$: Observable<Employee> = this.store.select(UserMenuState?.userDetails);

  public remainingTokenTimeText$: Observable<string> = this.tokenService.remainingTimeText$;
  public tokenTimeWarningStyle$: Observable<'ok' | 'alarming' | 'urgent'> = this.tokenService.timeWarningStyle$;

  public menuItems$: Observable<UserMenuItem[]>;

  constructor(
    private store: Store,
    private elementRef: ElementRef,
    private eventsService: GlobalEventsService,
    private authService: AuthService,
    private setUserImageService: SetUserImageService,
    private languageMenuService: LanguageMenuService,
    public emulationService: EmulationService,
    public tokenService: TokenService,
    private destroyRef: DestroyRef
  ) {
    this.tokenService.init(ConfirmationDialogComponent);
  }

  public async ngOnInit(): Promise<void> {
    this.user$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(user => {
      if (user) {
        this.store.dispatch(new LoadUserImage(user));
        this.store.dispatch(new LoadUserDetails(user));
      }
    });

    await this.setupMenuItems();

    this.tokenService.startCheckingTimeTillLogout();

    this.eventsService
      .listen('click')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(e => this.clickoutHandler(e));
  }

  public toggleMenu(): void {
    this.isMenuVisible$.next(!this.isMenuVisible$.value);
  }

  public openMenuItem(item?: UserMenuItem): void {
    if (item?.subMenuItems?.length > 0) {
      item.submenuactive = !item.submenuactive;
    } else if (item?.action) {
      item.action();

      this.toggleMenu();
    }
  }

  public async renewToken(): Promise<void> {
    await this.tokenService.renewToken();
  }

  private clickoutHandler(event: MouseEvent): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      if (this.isMenuVisible$.value) {
        this.toggleMenu();
      }
    }
  }

  private async setupMenuItems(): Promise<void> {
    // As long as user profile are not implemented the menu item will remain commented out
    this.menuItems$ = this.userImageSrc$.pipe(
      map(imgSrc => {
        const menuItems: UserMenuItem[] = [
          // { name: 'header.user-menu.menu-item-profile', icon: 'person', submenuactive: false },
          {
            name: !imgSrc ? 'header.user-menu.menu-item-add-picture' : 'header.user-menu.menu-item-modify-picture',
            icon: 'edit',
            action: () => this.setUserImageService.setPersonalPicture()
          },
          {
            name: `header.user-menu.languages.${this.languageMenuService.selectedLanguage}`,
            icon: 'language',
            subMenuItems: this.languageMenuService.getSelectableLanguageMenuItems()
          }
        ];

        if (this.emulationService.isEmulating) {
          menuItems.push({ name: 'header.user-menu.menu-item-stop-emulate', icon: 'person_off', action: () => this.emulationService.removeEmulation() });
        } else if (this.emulationService.isEmulationPermitted()) {
          menuItems.push({ name: 'header.user-menu.menu-item-emulate', icon: 'people', action: () => this.emulationService.selectEmulationUser() });
        }

        menuItems.push({ name: 'header.user-menu.menu-item-log-out', icon: 'logout', action: () => this.authService.logOff() });

        return menuItems;
      })
    );
  }
}
