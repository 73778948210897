import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, ElementRef, OnInit } from '@angular/core';
import { ContentLanguage, StorageKey } from '@coin/shared/util-enums';
import { GlobalEventsService } from '@coin/shared/util-helpers';
import { FaqItem } from '@coin/shared/util-models';
import { TranslateService } from '@ngx-translate/core';
import { filter, finalize, iif, map, of, switchMap, tap } from 'rxjs';
import { FaqService, StorageService } from '@coin/shared/data-access';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { environment } from '@coin/shared/util-environments';

@Component({
  selector: 'cosmos-help-menu',
  templateUrl: './help-menu.component.html',
  styleUrls: ['./help-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    [
      trigger('slideFaq', [
        transition(':enter', [style({ right: '-450px' }), animate('400ms ease-in', style({ right: '0px' }))]),
        transition(':leave', [style({ right: '0px' }), animate('400ms ease-out', style({ right: '-450px' }))])
      ])
    ]
  ],
  standalone: false
})
export class HelpMenuComponent implements OnInit {
  public isHelpMenuVisible = false;

  public isHelpDataLoading = false;
  public languageSelected: ContentLanguage = ContentLanguage.ENGLISH;
  public helpData: { [lang in ContentLanguage]?: FaqItem[] } = {};

  public totalNewFlags = 0;

  clickout = (event: MouseEvent): void => {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      if (this.isHelpMenuVisible) {
        this.toggleMenu();
      }
    }
  };

  constructor(
    private translateService: TranslateService,
    private storageService: StorageService,
    private faqService: FaqService,
    private eventsService: GlobalEventsService,
    private elementRef: ElementRef,
    private cdr: ChangeDetectorRef,
    private destroyRef: DestroyRef
  ) {}

  public async ngOnInit(): Promise<void> {
    await this.loadTranslations();
    this.eventsService
      .listen('click')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(event => this.clickout(event));
  }

  public toggleMenu(): void {
    this.isHelpMenuVisible = !this.isHelpMenuVisible;
    this.cdr.detectChanges();
  }

  public onOpenOtherFaqTopic(title: string): void {
    if (title === 'helpdesk') {
      window.open(environment.helpdeskUrl, '_blank');
    }
  }

  private async loadTranslations(): Promise<void> {
    this.languageSelected = await this.storageService.get(StorageKey.LANGUAGE);
    await this.loadHelpData();

    this.translateService.onLangChange.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(lang => {
      this.languageSelected = lang.lang as ContentLanguage;
      setTimeout(() => {
        this.loadHelpData();
      }, 600);
    });
  }

  private async loadHelpData(): Promise<void> {
    if (!this.helpData[this.languageSelected]) {
      this.isHelpDataLoading = true;
      this.faqService
        .getFaq(`help_${this.languageSelected}_publish.json`)
        .pipe(
          switchMap(data => iif(() => !data, this.faqService.getFaq(`help_en_publish.json`), of(data))),
          filter(helpItem => !!helpItem),
          // For now we only show the helpdesk item. Later on we will have to make changes in the faqService to only show items specific for COIN or COSMOS
          map(helpItems => helpItems.filter(helpItem => helpItem.generalName?.toLowerCase() === 'helpdesk')),
          tap(helpItems => {
            this.searchForNewFlagItems(helpItems);
            this.helpData[this.languageSelected] = helpItems;
          }),
          finalize(() => {
            this.isHelpDataLoading = false;
          })
        )
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe();
    }
  }

  private searchForNewFlagItems(helpItems: FaqItem[]): void {
    this.totalNewFlags = 0;
    for (const item of helpItems) {
      item.newFlagCount = this.checkItemsForFlag(item);
      this.totalNewFlags += item.newFlagCount;
    }
  }

  private checkItemsForFlag(item: FaqItem): number {
    if (item && item.content && !item.isAnswer) {
      let contentNewFlagCount = 0;
      if (item.newFlagUntil) {
        contentNewFlagCount += 1;
      } else {
        for (const itm of item.content) {
          itm.newFlagCount = this.checkItemsForFlag(itm);
          contentNewFlagCount += itm.newFlagCount;
        }
      }
      return contentNewFlagCount;
    }
    if (item && item.content && item.isAnswer) {
      if (item.newFlagUntil) {
        const timeUntilEndNewFlag = new Date(item.newFlagUntil).getTime() - new Date(new Date().setHours(0, 0, 0, 0)).getTime();
        if (timeUntilEndNewFlag > 0) {
          return 1;
        }
      }
    }
    return 0;
  }
}
