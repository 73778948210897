import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'coin-org-tree-frame',
  templateUrl: './org-tree-frame.component.html',
  styleUrls: ['./org-tree-frame.component.scss'],
  imports: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrgTreeFrameComponent {
  @Input({ required: true }) title: string;
}
