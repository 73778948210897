import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'coin-org-tree-sub-frame',
  templateUrl: './org-tree-sub-frame.component.html',
  styleUrls: ['./org-tree-sub-frame.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIconModule, TranslateModule]
})
export class OrgTreeSubFrameComponent {
  @Input({ required: true }) title: string;

  @Output() closeClicked = new EventEmitter<void>();
}
