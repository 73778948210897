import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, ElementRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { environment } from '@coin/shared/util-environments';
import { GlobalEventsService } from '@coin/shared/util-helpers';

@Component({
  selector: 'cosmos-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [
    trigger('animateEnterLeave', [
      transition(':enter', [style({ opacity: '0' }), animate('200ms ease-in', style({ opacity: '1' }))]),
      transition(':leave', [style({ opacity: '1' }), animate('200ms ease-out', style({ opacity: '0' }))])
    ]),
    trigger('showMenu', [
      state('inactive', style({ visibility: 'hidden' })),
      state('active', style({ visibility: 'visible' })),
      transition('active => inactive', [animate('400ms linear')])
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class SidebarComponent implements OnInit {
  public isSidebarVisible = false;

  public titles: string[] = ['Apps'];

  public selectedMenuItem: string = this.titles[0];

  public environment = environment;

  clickout = (event: MouseEvent): void => {
    if (!this.eRef.nativeElement.contains(event.target)) {
      if (this.isSidebarVisible) {
        this.onSidebarToggle();
        this.cdr.detectChanges();
      }
    }
  };

  constructor(
    private eRef: ElementRef,
    private events: GlobalEventsService,
    private cdr: ChangeDetectorRef,
    private destroyRef: DestroyRef
  ) {}

  public ngOnInit(): void {
    this.events
      .listen('click')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(e => this.clickout(e));
  }

  public onSidebarToggle(title?: string): void {
    if (title) {
      this.selectedMenuItem = title;
    }

    this.isSidebarVisible = !this.isSidebarVisible;
  }

  public toggleMenuItem(menuItem: string): void {
    if (this.selectedMenuItem === menuItem) {
      this.selectedMenuItem = '';
    } else {
      this.selectedMenuItem = menuItem;
    }
  }
}
