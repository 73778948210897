import { Pipe, PipeTransform } from '@angular/core';
import { Employee } from '../../api/generated';

@Pipe({
  name: 'name',
  standalone: false
})
export class NamePipe implements PipeTransform {
  transform(user: Employee): string {
    return user ? NamePipe.formatEmployee(user as Employee) : '';
  }

  public static formatEmployee(employee: Employee): string {
    return [employee?.title, employee?.firstname, employee?.lastnamePrefix, employee?.lastname].filter(Boolean).join(' ').trim();
  }
}
