import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'coin-siemens-energy-faq-answer-level',
  templateUrl: './faq-answer-level.component.html',
  styleUrls: ['./faq-answer-level.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class FaqAnswerLevelComponent {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() items: any;

  public openPage(link: string): void {
    window.open(link, '_blank');
  }
}
