import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { map, Observable, timer } from 'rxjs';
import { AuthComponentsModule } from '@coin/modules/auth/feature';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EmployeeProfile, LocationPipe } from '@coin/modules/org-tree/util';
import { Organisation, Position } from '@coin/shared/util-models';
import { CopyOnClickDirective } from '@coin/shared/util-directives';
import { V2ButtonComponent } from '@coin/shared/ui-storybook';
import { ContactButtonBarComponent } from './components/contact-button-bar/contact-button-bar.component';
import { ManagerFlagComponent } from './components/manager-flag/manager-flag.component';

@Component({
  selector: 'coin-org-tree-employee-profile',
  templateUrl: './employee-profile.component.html',
  styleUrls: ['./employee-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    AuthComponentsModule,
    MatIconModule,
    TranslateModule,
    MatTooltipModule,
    ContactButtonBarComponent,
    LocationPipe,
    CopyOnClickDirective,
    ManagerFlagComponent
  ]
})
export class EmployeeProfileComponent {
  @Input({ required: true }) employeeProfile: EmployeeProfile;

  @Input() showContactInfo = true;

  @Input() showManagement = true;

  @Input() showLineManagerFlag = false;

  @Input() showManagerExitDateWarning = false;

  @Input() additionalPositions: Position[];

  @Input() currentPosition: Position;

  @Input() currentOrganisation: Organisation;

  @Input() isReadOnly = true;

  @Input() managerDisplay: 'gid' | 'managerType' = 'managerType';

  @Output() closeProfile = new EventEmitter<void>();

  @Output() goToLegalEntity = new EventEmitter<{ are: string }>();

  @Output() goToOrgCode = new EventEmitter<{ orgCode: string; employeeGid?: string; employeeOrgCode?: string }>();

  public currentTime$: Observable<Date> = timer(0, 60000).pipe(map(() => new Date()));

  public defaultTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  public get currentPositionInfo(): string[] {
    const positionInfo: string[] = [];
    if (this.currentPosition?.isHead) positionInfo.push(this.currentPosition.title);
    if (this.currentOrganisation) positionInfo.push(this.currentOrganisation.name);
    return positionInfo;
  }
}
