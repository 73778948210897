import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HealthCheckService, StorageService } from '@coin/shared/data-access';
import { ContentLanguage, StorageKey } from '@coin/shared/util-enums';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, catchError, combineLatest, map, Observable, throwError } from 'rxjs';
import { AuthService } from '@coin/modules/auth/data-access';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UpdateNotificationsService } from '@coin/shared/util-helpers';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class AppComponent implements OnInit {
  public isHeaderVisible = false;
  public healthCheckFail$ = new BehaviorSubject<boolean>(false);
  public isSideBarVisible$: Observable<boolean> = combineLatest([this.healthCheckFail$, this.authService.isAuthenticated$]).pipe(
    map(([healthCheckFail, isAuthenticated]) => !healthCheckFail && isAuthenticated)
  );

  constructor(
    private translateService: TranslateService,
    private storageService: StorageService,
    private authService: AuthService,
    private healthCheckService: HealthCheckService,
    private router: Router,
    private destroyRef: DestroyRef
  ) {
    inject(UpdateNotificationsService).checkForUpdates();
  }

  public ngOnInit(): void {
    this.translateService.setDefaultLang(ContentLanguage.ENGLISH);
    this.setupLang();
    this.checkBackendStatus();

    this.isHeaderVisible = !window.location.href.includes('/login');
  }

  private async setupLang(): Promise<void> {
    const lang = await this.storageService.get(StorageKey.LANGUAGE);

    if (lang) {
      this.translateService.reloadLang(lang);
    }

    this.translateService.use(lang ?? ContentLanguage.ENGLISH);
  }

  private checkBackendStatus(): void {
    this.healthCheckService
      .ping()
      .pipe(
        catchError(err => {
          this.healthCheckFail$.next(true);
          this.router.navigate(['fallback'], { skipLocationChange: true });
          return throwError(() => err);
        })
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }
}
