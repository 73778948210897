import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@coin/modules/auth/data-access';

@Component({
  selector: 'coin-siemens-energy-logout-screen',
  templateUrl: './logout-screen.component.html',
  styleUrls: ['./logout-screen.component.scss'],
  standalone: false
})
export class LogoutScreenComponent implements OnInit {
  constructor(
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.authService.logOff();
  }

  login(): void {
    this.router.navigate(['/']);
  }
}
