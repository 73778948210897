import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, signal } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { FaqDrill, FaqItem } from '@coin/shared/util-models';
import { FaqService } from '@coin/shared/data-access';
import { AuthService } from '@coin/modules/auth/data-access';

@Component({
  selector: 'coin-siemens-energy-faq-menu',
  templateUrl: './faq-menu.component.html',
  styleUrls: ['./faq-menu.component.scss'],
  animations: [
    [
      trigger('shrinkExpand', [
        transition(':enter', [
          style({ height: '0px', minHeight: '0px', maxHeight: '0px', opacity: 0 }),
          animate('250ms linear', style({ height: '*', minHeight: '*', maxHeight: '*', opacity: 1 }))
        ]),
        transition(':leave', [animate('250ms linear', style({ height: '0px', minHeight: '0px', maxHeight: '0px', opacity: 0 }))])
      ])
    ]
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class FaqMenuComponent {
  private _helpData: FaqItem[] = [];

  @Input()
  public get helpData(): FaqItem[] {
    return this._helpData;
  }
  public set helpData(value: FaqItem[]) {
    this._helpData = value;
    this.reduceOnePage(true);
  }

  @Input() public isLoading = false;
  @Input() public isSearchBarVisible = true;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() public close = new EventEmitter();
  @Output() public openOtherTopic = new EventEmitter<string>();

  public faqTabs = signal<FaqItem[]>([]);
  public search = signal('');
  public faqTabIndex = signal(0);
  public generalTabIndex = signal(0);
  public topicTitle = signal('help');
  public subtitle = signal<string | boolean>(undefined);
  public faqMoreWidth = signal(false);
  private searchActive = false;

  constructor(
    private authService: AuthService,
    private faqService: FaqService,
    private cdr: ChangeDetectorRef
  ) {}

  public searchFaq(): void {
    const searchItem = this.search().trim().toLowerCase();
    this.faqTabs.set([]);
    if (this.search() && this.search() !== '') {
      const allAnswers = this.getAllFaqAnswers();
      const finalSearchResult: FaqItem[] = [];
      for (const item of allAnswers) {
        if (JSON.stringify(item.content).toLowerCase().indexOf(searchItem) > -1 || JSON.stringify(item.title).toLowerCase().indexOf(searchItem) > -1) {
          finalSearchResult.push(item);
        }
      }
      this.searchActive = true;
      this.openFaqItem({ content: finalSearchResult } as FaqItem, 2, false, 'search-result', false);
    } else {
      this.reduceOnePage(true);
    }
  }

  public closeFaq(): void {
    this.close.emit();
  }

  public openFaqFromSub(data: FaqDrill): void {
    this.openFaqItem(data.content, data.level, data.moreWidth, data.title, data.subtitle);
  }

  public onOpenOtherTopic(data: { title: string }): void {
    this.openOtherTopic.emit(data.title);
  }

  private openFaqItem(faqItem: FaqItem, level: number, moreWidth = false, title?: string | boolean, subtitle?: string | boolean): void {
    if (title || (level === 1 && faqItem.title)) {
      this.topicTitle.set(faqItem.title);
    }
    if (!faqItem.isAnswer) {
      this.subtitle.set(subtitle);
    }
    this.faqMoreWidth.set(moreWidth);
    if (level > 0) {
      setTimeout(async () => {
        const itemsToBeRemoved: number[] = [];
        for (let i = 0; i < faqItem.content.length; i++) {
          const itm = faqItem.content[i];
          if (itm.requirement) {
            if (itm.requirement.type === 'isROW') {
              const user = await this.authService.getUser();
              if (user && user['custom:country']) {
                if (user['custom:country'] === 'DE') {
                  if (itm.requirement.value === true) {
                    itemsToBeRemoved.push(i);
                  }
                } else if (itm.requirement.value === false) {
                  itemsToBeRemoved.push(i);
                }
              }
            }
          }
        }
        for (const itm of itemsToBeRemoved) {
          faqItem.content.splice(itm, 1);
        }
        this.faqTabs.update(tabs => [...tabs, faqItem]);
        this.faqTabIndex.update(index => index + 1);
        this.cdr.markForCheck();
      }, 100);
    }
  }

  public reduceOnePage(clear?: boolean): void {
    let newSubtitle: string | boolean = false;
    if ((this.searchActive && this.faqTabIndex() === 1) || clear) {
      this.faqTabIndex.set(0);
      this.search.set('');
      this.topicTitle.set('help');
      this.faqTabs.set([]);
    } else if (this.faqTabIndex() === 2) {
      newSubtitle = this.subtitle();
    }
    this.subtitle.set(newSubtitle);
    this.faqMoreWidth.set(false);
    if (this.faqTabIndex() > 0) {
      this.faqTabIndex.update(index => index - 1);
      this.faqTabs.update(tabs => {
        tabs.pop();
        return [...tabs];
      });
      if (this.faqTabIndex() === 0) {
        this.topicTitle.set('help');
        this.search.set('');
      }
    } else if (this.generalTabIndex() > 0) {
      this.generalTabIndex.set(0);
      this.faqTabs.set([]);
    }
  }

  private getAllFaqAnswers(): FaqItem[] {
    const finalList: FaqItem[] = [];
    for (const topItem of this.helpData) {
      this.iterateToBottom(topItem, finalList);
    }
    return finalList;
  }

  private iterateToBottom(list: FaqItem, finalList: FaqItem[]): void {
    if (list && list.isAnswer) {
      finalList.push(list);
    } else if (list) {
      for (const item of list.content) {
        this.iterateToBottom(item, finalList);
      }
    }
  }
}
