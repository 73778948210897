import { Component, Input } from '@angular/core';

@Component({
  selector: 'coin-siemens-energy-coin-app',
  templateUrl: './coin-app.component.html',
  styleUrls: ['./coin-app.component.scss'],
  standalone: false
})
export class CoinAppComponent {
  @Input() app: { name: string; icon: string };
  @Input() disabled?: boolean;
}
