import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { AuthComponentsModule } from '@coin/modules/auth/feature';
import { LoadingModule } from '@coin/shared/data-access';
import { toggleIconAnimation } from '@coin/shared/util-animations';
import { Organisation, Position } from '@coin/shared/util-models';
import { EmployeeNamePipe } from '@coin/shared/util-pipes';
import { EllipsisTooltipDirective } from '@coin/shared/util-directives';
import { OrgBoxMenuItem } from '@coin/modules/org-tree/util';
import { OrgTreeComponentState } from '../org-tree/org-tree.component.state';

@Component({
  selector: 'coin-org-box',
  templateUrl: './org-box.component.html',
  styleUrls: ['./org-box.component.scss'],
  imports: [CommonModule, MatIconModule, MatTooltipModule, TranslateModule, AuthComponentsModule, LoadingModule, EllipsisTooltipDirective, MatMenuModule],
  providers: [EmployeeNamePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [toggleIconAnimation]
})
export class OrgBoxComponent implements OnChanges {
  @Input({ required: true }) organisation: Organisation;

  @Input() selectable = true;

  @Input() hidePositionsList = false;

  @Input() selectedEmployeeId: string;

  @Input() isSelected = false;

  @Input() showPositionsList = false;

  @Input() showDirectsCount = true;

  @Input() orgBoxActions: OrgBoxMenuItem[];

  @Output() selected = new EventEmitter<void>();

  @Output() openJobsClicked = new EventEmitter<Organisation>();

  @Output() positionSelected = new EventEmitter<Position>();

  @Output() positionListOpened = new EventEmitter<void>();

  private state = inject(OrgTreeComponentState);
  private employeeNamePipe = inject(EmployeeNamePipe);

  public positions$: Observable<Position[]>;
  public positionsLoading$: Observable<boolean>;

  public isPositionsListVisible = false;

  public get headsDisplayString(): string {
    if (!(this.organisation?.heads?.length > 0)) {
      return '';
    }

    return this.organisation.heads
      .filter(head => !!head?.employee)
      .map(head => `${head.isActing ? 'act. ' : ''}${this.employeeNamePipe.transform(head.employee)}`)
      .join(', ');
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.organisation && (changes.organisation.isFirstChange || changes.organisation.previousValue?.id !== changes.organisation.currentValue?.id)) {
      this.positions$ = this.state.selectPositions(this.organisation.id);
      this.positionsLoading$ = this.state.selectPositionsLoading(this.organisation.id);

      this.isPositionsListVisible = false;
    }

    if (changes.showPositionsList?.currentValue && !this.isPositionsListVisible) {
      this.togglePositionList();
    }

    if (changes.hidePositionsList?.currentValue) {
      this.isPositionsListVisible = false;
    }
  }

  public select(): void {
    if (this.selectable) {
      this.selected.emit();
    }
  }

  public get hasOpenJobs(): boolean {
    return this.organisation?.numberOfOpenJobs > 0;
  }

  public togglePositionList(): void {
    this.isPositionsListVisible = !this.isPositionsListVisible;

    if (this.isPositionsListVisible) {
      this.positionListOpened.emit();
    }
  }
}
