import { Component } from '@angular/core';
import { AuthService } from '@coin/modules/auth/data-access';

@Component({
  selector: 'coin-siemens-energy-login-screen',
  templateUrl: './login-screen.component.html',
  styleUrls: ['./login-screen.component.scss'],
  standalone: false
})
export class LoginScreenComponent {
  constructor(private auth: AuthService) {}
  public login(): void {
    this.auth.login();
  }
}
